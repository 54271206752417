import "hacktimer/HackTimer"
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
import "./assets/css/responsive.css";
import "./i18n/i18n";
import { history } from "./history";
import { GlobalVariable } from "./util/index";
import {
  setAuthTokenAction,
  setIsLoginAction,
  setAuthRefreshTokenAction,
  setLoginUserAction,
  getProfile,
  getMerchantSetting,
  getLoginUserData,
  refresTokenIntervalAction,
} from "./redux/actions/auth/index";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "font-awesome/css/font-awesome.min.css";
import { Toaster } from 'react-hot-toast';
import { encryptStorage } from "./util/encryptStorage";
import { connectSocket } from "./util/socket";

const LazyApp = lazy(() => import("./App"));
connectSocket();
const jsx = (
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <Layout>
        <IntlProviderWrapper>
          <LazyApp />
          {/* <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          /> */}
          <Toaster 
            position="top-right" 
            toastOptions={{
              style:{
                backgroundColor:"#1a1a1a",
                color:"white",
                fontSize:"15px"
              }
            }}
            />
        </IntlProviderWrapper>
      </Layout>
    </Suspense>
  </Provider>
);

let hasRendered = false;

//renderApp
const renderApp = async () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("root"));
    hasRendered = true;
  }
};

ReactDOM.render(<Spinner />, document.getElementById("root"));
serviceWorker.unregister();

/**
 * @name renderLogin
 * @description To render login page/
 */
const renderLogin = () => {
  store.dispatch(setIsLoginAction(false));

  renderApp();

  if (
    window.location.pathname === "/" ||
    window.location.pathname === GlobalVariable.windowGlobelPathFull
  ) {
    history.push("/login");
  }
};

/**
 * @name tryLogin
 * @description try to login with token,
 * if token get expire then try login with refresh token ,
 * and if refresh get expire then user will redirect to login page.
 */
const tryLogin = async () => {
  try {
    const login = encryptStorage.getItem(GlobalVariable.localStoreName);

    if (login) {
      let loginObject = login;
      if (loginObject.permission && loginObject.permission.length > 0) {
        await store.dispatch(setAuthTokenAction(loginObject.token));

        if (
          loginObject.role === GlobalVariable.manager ||
          loginObject.role === GlobalVariable.merchantUser
        ) {
          let data = await store.dispatch(getLoginUserData(loginObject.id));
          if (data && data.branchID) {
            loginObject.branchId = data.branchID;
            loginObject.serviceId = data.serviceID;
            loginObject.queueId = data.queueID;
          }
          if (data && data.permission) {
            loginObject.permission = data.permission;
          }
        }
        await store.dispatch(setLoginUserAction(loginObject));
        await store.dispatch(setIsLoginAction(true));
        await store.dispatch(
          setAuthRefreshTokenAction(loginObject.refreshToken)
        );
        await store.dispatch(getProfile(loginObject.name));
        await getMerchantSetting(loginObject.email);
        await refresTokenIntervalAction();
        renderApp();
        if (
          window.location.pathname === "/" ||
          window.location.pathname === GlobalVariable.windowGlobelPathFull ||
          window.location.pathname === GlobalVariable.windowGlobelPathFull
        ) {
          history.push("/");
        } else if (
          window.location.pathname === GlobalVariable.windowGlobelPathLogin ||
          // window.location.pathname === "/admin/login"
          window.location.pathname === GlobalVariable.windowGlobelPathWithLogin
        ) {
          store.dispatch(setIsLoginAction(false));
          encryptStorage.removeItem(GlobalVariable.localStoreName);
        } else {
          // history.push(window.location.pathname);
        }
      } else {
        if (
          window.location.pathname === GlobalVariable.windowConfirmPassword ||
          window.location.pathname ===
            GlobalVariable.windowPathWithConfirmPassword
        ) {
          history.push("/login");
        }

        renderLogin();
      }
    } else {
      if (
        window.location.pathname === GlobalVariable.windowConfirmPassword ||
        window.location.pathname ===
          GlobalVariable.windowPathWithConfirmPassword
      ) {
        history.push("/login");
      }

      renderLogin();
    }
  } catch (e) {
    renderLogin();
  }
};

tryLogin();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
