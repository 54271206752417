export class GlobalVariable {
  static apiUrl = {
    // test server
    mainUrl: "https://api.test.balador.io/user/",
    queueApiUrl: "https://api.test.balador.io/queue/",
    notificationUrl: "https://api.test.balador.io/notification/",
    reportUrl: "https://api.test.balador.io/report/",
    socketUrl: "https://api.test.balador.io",
  };
  static customerApiUrl = "https://test.balador.io/user/branch"; // change balador-customer to user in live
  static customerEventUrl = "https://test.balador.io/user/bookticket"; // change balador-customer to user in live

  static apiResponseStatus = {
    success: 200,
    error: 400,
    unAuthorized: 403,
    serverError: 500,
  };

  static localStoreName = "merchant-dooree";
  static loginId = "merchant-dooree-login-id";
  static merchantToken = "merchant-token";
  static isPChanged = "is-p-changed";

  static windowGlobelPathFull = "/merchant/";
  static windowGlobelPath = "/merchant";
  static windowGlobelPathWithLogin = "/merchant/login";
  static windowGlobelPathLogin = "/login";

  static windowConfirmPassword = "/forgot-password/confirm";
  static windowPathWithConfirmPassword = "/merchant/forgot-password/confirm";

  static user = "user";
  static all = "all";
  static manager = "manager";
  static merchant = "merchant";
  static merchantUser = "merchant-user";
  static regionalmanager = "regionalmanager";
  static branchManager = "Branch Manager";
  static regionalManager = "Regional Manager";
  static agent = "agent";
  static concierge = "concierge";
  static userEmail = "user-email";
  static secreteKey = process.env.REACT_APP_ENCRYPT_STORAGE_SECRETE_KEY;
  static appointmentAgent = "appointmentagent";
  static eserviceagent = "eserviceagent";
  static donationagent = "donationagent";
  static roles = {
    agent: "Queue Agent",
    "virtual-agent": "Virtual Agent",
    concierge: "Concierge",
    manager: "Manager",
    "merchant-user": "Merchant User",
    "appointmentagent":"Appointment Agent",
    "eserviceagent": "E-service Agent",
  }

  static localLang = "balador-merchant-local-lang-";
  static english = 'en';
  static arabic = 'ar';

  static ETicketStatus = {
    todo:"",
    processed:"processed",
    completed:"completed",
    cancelled:"cancelled"
  }
  static hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  static errorMessage = "Oops! Something went wrong on our end. Please try again later or contact our support team."
  
  static PhoneEncryptionCustomKey = process.env.REACT_APP_PHONE_ENCRYPTION_CUSTOME_KEY
  static PhoneEncryptionCustomIV = process.env.REACT_APP_PHONE_ENCRYPTION_CUSTOME_IV

  static upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  static lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
  static numbers = "0123456789";
  static symbol = "^#$^+=!*()@%&";
}