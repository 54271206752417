import { ADD_USER } from "../types"
import { api } from "../../../api/api"
import { QueueApi } from "../../../api/queueApi"
import toast from "react-hot-toast"
import { GlobalVariable } from "../../../util/index"
import { history } from "../../../history"
import { getTranslation } from "../../../i18n/i18n"
import { setLoaderAction } from "../loader/index"
import {
  getTotalUserCount,
  invalidPasswordCheck,
  genaretPublicKey,
  createEncryptString
} from "../auth/index"
import { isValidPhoneNumber } from "libphonenumber-js"
const api_status = GlobalVariable.apiResponseStatus

/**
 @name  addUserAction
 @file user.js
 @description This method is used for create new user
 */
export const addUserAction = (user, permissions) => {
  return async (dispatch, store) => {
    let {
      name,
      email,
      password,
      role,
      status,
      // branch,
      phone,
      countryCode,
      branches,
      eservices,
      queues,
      service
    } = user
    await dispatch(genaretPublicKey())
    if (!name) {
      toast.error(getTranslation("GLOBLE_MESSAGE.nameRequired"))
      return true
    }

    if (!email) {
      toast.error(getTranslation("GLOBLE_MESSAGE.emailRequired"))
      return true
    }

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      toast.error(getTranslation("GLOBLE_MESSAGE.invalidEmail"))
      return true
    }
    if (!password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.passwordRequired"))
      return true
    }

    const isPassword = await invalidPasswordCheck(password)
    if (isPassword) {
      return true
    }
    let encrytPassword = await createEncryptString(password)

    if (!role.value) {
      toast.error(getTranslation("GLOBLE_MESSAGE.roleRequired"))
      return true
    }

    if (!status.value) {
      toast.error(getTranslation("GLOBLE_MESSAGE.statusRequired"))
      return true
    }

    if (phone && !countryCode.value) {
      toast.error(getTranslation("USER.CountryCodeisRequired"))
      return true
    }
    if (phone && !isValidPhoneNumber(`+${countryCode.value}${phone}`)) {
      toast.error(getTranslation("USER.InvalidPhoneNumberMessage"))
      return true
    }
    if (role.value === GlobalVariable.manager) {
      if (branches.length === 0 || !branches) {
        toast.error(getTranslation("GLOBLE_MESSAGE.branchRequired"))
        return true
      }

      if (queues.length === 0 || !queues) {
        toast.error(getTranslation("GLOBLE_MESSAGE.queueRequired"))
        return true
      }
      if (service.length === 0 || !service) {
        toast.error(getTranslation("GLOBLE_MESSAGE.serviceRequired"))
        return true
      }
    }

    if ((role.value === GlobalVariable.eserviceagent || role.value === GlobalVariable.donationagent) && (!eservices || eservices.length === 0)) {
      toast.error(getTranslation("USER.servicesRequired"))
      return true
    }

    dispatch(setLoaderAction(true))
    // pass permission data stricky in Array of object
    let temp = []
    let dashboardTab = { dashboardTab: permissions.dashboardTab }
    let serviceTab = { serviceTab: permissions.serviceTab }
    let queueTab = { queueTab: permissions.queueTab }
    let userTab = { userTab: permissions.userTab }
    let branchTab = { branchTab: permissions.branchTab }
    let feedbackTab = { feedbackTab: permissions.feedbackTab }
    let settingTab = { settingTab: permissions.settingTab }
    let logTab = { logTab: permissions.logTab }
    let reportTab = { reportTab: permissions.reportTab }
    let workProfileTab = { workProfileTab: permissions.workProfileTab }
    let categoryTab = { categoryTab: permissions.categoryTab }
    let eventTab = { eventTab: permissions.eventTab }
    let formTab = { formTab: permissions.formTab }
    let livefeedTab = { livefeedTab: permissions.livefeedTab }
    let appointmentsDashboardTab = { appointmentsDashboardTab: permissions.appointmentsDashboardTab }
    let eservicesDashboardTab = { eservicesDashboardTab: permissions.eservicesDashboardTab }
    let instantTicketsLogTab = { instantTicketsLogTab: permissions.instantTicketsLogTab }
    let appointmentsLogTab = { appointmentsLogTab: permissions.appointmentsLogTab }
    let eservicesLogTab = { eservicesLogTab: permissions.eservicesLogTab }
    let donationDashboardTab = { donationDashboardTab: permissions.donationDashboardTab }
    let donationLogTab = { donationLogTab: permissions.donationLogTab }

    temp.push(
      dashboardTab,
      serviceTab,
      queueTab,
      userTab,
      branchTab,
      feedbackTab,
      settingTab,
      logTab,
      reportTab,
      workProfileTab,
      categoryTab,
      eventTab,
      formTab,
      livefeedTab,
      appointmentsDashboardTab,
      eservicesDashboardTab,
      instantTicketsLogTab,
      appointmentsLogTab,
      eservicesLogTab,
      donationDashboardTab,
      donationLogTab,
    )

    let branchID = branches.map((item) => item.value)
    let userData = {
      name,
      email,
      password: encrytPassword.encryt,
      keyId: store()?.auth?.login?.publickKey?.id,
      key: encrytPassword.frontEndKey,
      merchantID: store().auth.login.user.merchantID,
      phone,
      isActive: status.value === "Active" ? true : false,
      role: role.value,
      status: status.value,
      permission: temp,
      isGovMerchant:store().auth.login.currentUser.isGovernment
      // serviceID,
      // branchID: branchID,
    }
    // if(branch.value){
    //     userData['branchID'] = branch.value;
    // }
    if (role.value === GlobalVariable.manager) {
      userData["branchID"] = branchID
      userData["queueID"] = queues.map((item) => item.value)
      userData["serviceID"] = [...service, ...eservices].map(obj => obj.value);
    }

    if (countryCode.value) {
      userData["countryCode"] = countryCode.value
    }
    let userCreateData = await api("createUserByMerchant", userData, "post")

    if (userCreateData.status === api_status.success) {

      if (role.value === GlobalVariable.eserviceagent || role.value === GlobalVariable.donationagent) {
        const userdata = {
          isGovMerchant:store().auth.login.currentUser.isGovernment,
          id: userCreateData?.data?.data.userBranchId,
          merchantId: store().auth.login.user.merchantID,
          refAgentId: userCreateData?.data?.data.id,
          name: userCreateData?.data?.data.name,
          email: userCreateData?.data?.data.email,
          role: userCreateData?.data?.data.role,
          isActive: status.value === "Active" ? true : false,
          status: "a",
          ...(role.value === GlobalVariable.eserviceagent && { eservices: eservices.map(s => ({ id: s.value, name: s.label })) }),
          ...(role.value === GlobalVariable.donationagent && { donationService: eservices.map(s => ({ id: s.value, name: s.label })) }),
        };
        let UserQueue = await QueueApi("agent", [userdata], "post");
      }

      await dispatch(getTotalUserCount())
      dispatch(getUserAction({ role: "all", name: "", branch: "all" }))
      toast.success(getTranslation("USER.create"))
      dispatch(setLoaderAction(false))
      history.push("/users")
    } else {
      toast.error(
        userCreateData.message.errorCode + " " + userCreateData.message.message
      )
      dispatch(setLoaderAction(false))
    }
  }
}

/**
 @name  getUserAction
 @file user.js
 @description This method is used for get users
 */
export const getUserAction = (data) => {

  return async (dispatch, store) => {
    let { role, name, branch } = data

    let toSendData = {
      merchantID: store().auth.login.user.merchantID
    }

    if (role !== "all" && role) {
      toSendData['role'] = role
    }
    if (name) {
      toSendData['name'] = name
    }

    if (store().auth.login.user.role === GlobalVariable.manager) {
      if (store().auth.login.user.branchId) {
        toSendData["branch"] = store().auth.login.user.branchId.split(',')
      }
      if (store().auth.login.user.serviceId) {
        toSendData["serviceID"] = store().auth.login.user.serviceId
      }
    }

    if (data.status === "Active") {
      toSendData['isActive'] = true
    } else if (data.status === "Inactive") {
      toSendData['isActive'] = false
    }

    if (branch !== "all" && branch) {
      toSendData['branch'] = [branch]
    }


    let getUser = await api('getByMerchant', toSendData, "post")
    // 
    let filterData = getUser.data.data.user
    if (store()?.auth?.login?.currentUser?.isDonate) {
      const allowedRoles = [GlobalVariable.donationagent, GlobalVariable.merchantUser];
      filterData = filterData.filter(item => allowedRoles.includes(item.role));
    }
    if (getUser.status === api_status.success) {
      dispatch({
        type: ADD_USER,
        payload: { user: filterData }
      })
    }
  }
}

/**
 @name  getUserByIdAction
 @file user.js
 @description This method is used for get user bt id
 */
export const getUserByIdAction = async (id) => {
  let getUser = await api(`getByMerchantById/${id}`, {}, "get")

  if (getUser.status === api_status.success) {
    return { success: true, data: getUser.data.data }
    // toast.success('Category delete Successfully!');
  } else {
    return { success: false }
  }
}

/**
 @name  updateUserAction
 @file user.js
 @description This method is used for update user
 */
export const updateUserAction = (user, permissions) => {
  return async (dispatch, store) => {
    let {
      id,
      userID,
      name,
      role,
      status,
      service,
      // branch,
      phone,
      countryCode,
      selectedBranch,
      branchID,
      eservices,
      queueID,
      serviceID,
      originalStatus
    } = user
    if (!name) {
      toast.error(getTranslation("GLOBLE_MESSAGE.nameRequired"))
      return true
    }

    if (!role.value) {
      toast.error(getTranslation("GLOBLE_MESSAGE.roleRequired"))
      return true
    }

    if (!status.value) {
      toast.error(getTranslation("GLOBLE_MESSAGE.statusRequired"))
      return true
    }

    if (!serviceID.length === 0) {
      toast.error(getTranslation("GLOBLE_MESSAGE.serviceRequired"))
      return true
    }

    if (phone && !countryCode.value) {
      toast.error(getTranslation("USER.CountryCodeisRequired"))
      return true
    }

    if (phone && !isValidPhoneNumber(`+${countryCode.value}${phone}`)) {
      toast.error(getTranslation("USER.InvalidPhoneNumberMessage"))
      return true
    }
    if (
      status.value === 'Active' && originalStatus.value === 'Inactive' &&
      store().auth.login.availbleinActiveUser <= 0
    ) {
      toast.error(getTranslation("GLOBLE_MESSAGE.Pleaseinactiveotheruser"))
      return true
    }

    if (role.value === GlobalVariable.manager) {
      if (
        selectedBranch === null ||
        (selectedBranch && selectedBranch.length === 0)
      ) {
        toast.error(getTranslation("GLOBLE_MESSAGE.branchRequired"))
        return true
      }

      if (queueID.length === 0 || !queueID) {
        toast.error(getTranslation("GLOBLE_MESSAGE.queueRequired"))
        return true
      }
      if (serviceID.length === 0 || !serviceID) {
        toast.error(getTranslation("GLOBLE_MESSAGE.serviceRequired"))
        return true
      }
    }

    if ((role.value === GlobalVariable.eserviceagent || role.value === GlobalVariable.donationagent) && (!eservices || eservices.length === 0)) {
      toast.error(getTranslation("USER.servicesRequired"))
      return true
    }


    dispatch(setLoaderAction(true))
    // pass permission data stricky in Array of object
    let temp = []
    let dashboardTab = { dashboardTab: permissions.dashboardTab }
    let serviceTab = { serviceTab: permissions.serviceTab }
    let queueTab = { queueTab: permissions.queueTab }
    let userTab = { userTab: permissions.userTab }
    let branchTab = { branchTab: permissions.branchTab }
    let feedbackTab = { feedbackTab: permissions.feedbackTab }
    let settingTab = { settingTab: permissions.settingTab }
    let logTab = { logTab: permissions.logTab }
    let reportTab = { reportTab: permissions.reportTab }
    let workProfileTab = { workProfileTab: permissions.workProfileTab }
    let categoryTab = { categoryTab: permissions.categoryTab }
    let eventTab = { eventTab: permissions.eventTab }
    let formTab = { formTab: permissions.formTab }
    let livefeedTab = { livefeedTab: permissions.livefeedTab }
    let appointmentsDashboardTab = { appointmentsDashboardTab: permissions.appointmentsDashboardTab }
    let eservicesDashboardTab = { eservicesDashboardTab: permissions.eservicesDashboardTab }
    let instantTicketsLogTab = { instantTicketsLogTab: permissions.instantTicketsLogTab }
    let appointmentsLogTab = { appointmentsLogTab: permissions.appointmentsLogTab }
    let eservicesLogTab = { eservicesLogTab: permissions.eservicesLogTab }
    let donationDashboardTab = { donationDashboardTab: permissions.donationDashboardTab }
    let donationLogTab = { donationLogTab: permissions.donationLogTab }
    temp.push(
      dashboardTab,
      serviceTab,
      queueTab,
      userTab,
      branchTab,
      feedbackTab,
      settingTab,
      logTab,
      reportTab,
      workProfileTab,
      categoryTab,
      eventTab,
      formTab,
      livefeedTab,
      appointmentsDashboardTab,
      eservicesDashboardTab,
      instantTicketsLogTab,
      appointmentsLogTab,
      eservicesLogTab,
      donationDashboardTab,
      donationLogTab,
    )

    let userData = {
      id,
      userID,
      name,
      phone,
      merchantID: store().auth.login.user.merchantID,
      isActive: status.value === "Active" ? true : false,
      role: role.value,
      status: status.value,
      permission: temp,
      isGovMerchant:store().auth.login.currentUser.isGovernment
      // serviceID,
      // addService,
      // deleteService
      // branchID
    }
    // if(branch.value){
    //     userData['branchID'] = branch.value;
    // }
    if (countryCode.value) {
      userData["countryCode"] = countryCode.value
    }
    if (role.value === GlobalVariable.manager) {
      userData["branchID"] = selectedBranch.map((item) => item.value)
      userData["queueID"] = queueID.map((item) => item.value)
      userData["serviceID"] = [...serviceID, ...eservices].map(obj => obj.value);
    }
    let userUpdate = await api("updateUserByMerchant", userData, "put")

    if (userUpdate.status === api_status.success) {
      let data = [
        {
          id,
          status: "u",
          isActive: status.value === "Active" ? true : false,
          role: role.value,
          isGovMerchant:store().auth.login.currentUser.isGovernment,
          name,
          refAgentId: userUpdate?.data?.data.id,
          ...(role.value === GlobalVariable.eserviceagent && { eservices: eservices.map(s => ({ id: s.value, name: s.label })) }),
          ...(role.value === GlobalVariable.donationagent && { donationService: eservices.map(s => ({ id: s.value, name: s.label })) }),
        }
      ]
      if ((branchID || role.value === GlobalVariable.eserviceagent || role.value === GlobalVariable.donationagent) && ![GlobalVariable.manager,GlobalVariable.merchantUser].includes(role.value)) {
        await QueueApi("agent", data, "post")
      }
      await dispatch(getTotalUserCount())
      dispatch(getUserAction({ role: "all", name: "", branch: "all" }))
      toast.success(getTranslation("USER.update"))
      dispatch(setLoaderAction(false))
      history.push("/users")
    } else {
      toast.error(userUpdate.message.message)
      dispatch(setLoaderAction(false))
    }
  }
}

/**
 @name  deleteMerchantAction
 @file user.js
 @description This method is used for delete user
 */
export const deleteMerchantAction = (id, userID, role) => {
  return async (dispatch, store) => {
    dispatch(setLoaderAction(true))
    let deleteMerchant = await api(
      `deleteByMerchant?id=${id}&userID=${userID}`,
      {},
      "delete"
    )
    if (deleteMerchant.status === api_status.success) {
      await dispatch(getTotalUserCount())
      dispatch(getUserAction({ role: "all", name: "", branch: "all" }))
      dispatch(setLoaderAction(false))
      let deleteUser = [
        {
          id: id,
          status: "d"
        }
      ]
      if (["appointmentagent", "agent", "eserviceagent", "donationagent"].includes(role)) {
        await QueueApi(
          "agent",
          deleteUser,
          "post"
        );
      }
      toast.success(getTranslation("USER.delete"))
    } else {
      toast.error(deleteMerchant.message)
      dispatch(setLoaderAction(false))
    }
  }
}

/**
 @name  getQueueUserByIdAction
 @file user.js
 @description This method is used for get user bt id
 */
export const getQueueUserByIdAction = async (id) => {
  let getUser = await QueueApi(`agent?id=${id}`, {}, "get")

  if (getUser.status === api_status.success) {
    return { success: true, data: getUser.data.data }
  } else {
    return { success: false }
  }
}

/**
@name  getQueuesBranchAction
@file user.js
@description This method is used for get queues 
*/

export const getQueuesBranchAction = (branchid) => {

  return async (dispatch, store) => {

    try {
      const branchId = branchid.map((item) => item.value)

      const data = await QueueApi("queue-branch", { branchId }, "post")

      if (data.status === api_status.success) {
        return { success: true, data: data.data.data }
      } else {
        return { success: false }
      }
    }
    catch {
      toast.error("GLOBLE_MESSAGE.InternalServerError")
    }
  }
}


/**
@name  getServiceUserAction
@file user.js
@description This method is used for get queues 
*/

export const getServiceUserAction = (data) => {


  return async (dispatch, store) => {
    try {
      let sendToData = { merchantId: store().auth.login.user.merchantID }

      if (data.queueId.length > 0) {
        const queueIds = data.queueId.map((item) => item.value)
        sendToData["queueIds"] = queueIds
      }

      if (data.branchesId.length > 0) {
        const branchIds = data.branchesId.map((item) => item.value)
        sendToData["branchIds"] = branchIds
      }

      const serviceList = await api("service-by-queue", sendToData, "post")
      if (serviceList.status === api_status.success) {
        return { success: true, data: serviceList.data.data }
      } else {
        return { success: false }
      }
    }
    catch {
      toast.error("GLOBLE_MESSAGE.InternalServerError")
    }
  }
}

export const ResetPasswordAction = (data) => {
  return async (dispatch, store) => {
    try {
      if (!data.password) {
        toast.error(getTranslation('GLOBLE_MESSAGE.passwordRequired'));
        return false;
      }
      if (!data.confirmPassword) {
        toast.error(getTranslation('GLOBLE_MESSAGE.confirmPasswordRequired'));
        return false;
      }
      if (data.password !== data.confirmPassword) {
        toast.error(getTranslation('GLOBLE_MESSAGE.password&ConfirmNotSame'));
        return false;
      }
      await dispatch(genaretPublicKey());
      const isPassword = await invalidPasswordCheck(data.password);
      if (isPassword) {
        return false;
      }
      let encrytPassword = await createEncryptString(data.password);

      let dataToSend = {
        id: data?.id,
        password: encrytPassword.encryt,
        confirmPassword: encrytPassword.encryt,
        keyId: store()?.auth?.login?.publickKey?.id,
        key: encrytPassword.frontEndKey,
      }
      let resetPasswordRes = await api(`admin/reset`, dataToSend, "put");
      if (resetPasswordRes.status === api_status.success) {
        toast.success(getTranslation("GLOBLE_MESSAGE.ResetPassword"));
        return true
      } else {
        toast.error(resetPasswordRes?.message);
        return false
      }
    } catch (err) {
      console.log(err);
      return false
    }
  }
}